var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "helpCenter", staticStyle: { "font-size": "12px" } },
    [
      _c("div", {
        staticClass: "detail",
        domProps: { innerHTML: _vm._s(_vm.content) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }