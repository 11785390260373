<!--
 * @Author: soofly wy463554101@163.com
 * @Date: 2022-07-19 17:39:13
 * @LastEditors: tzk
 * @LastEditTime: 2023-02-14 15:08:21
 * @FilePath: /help.ringle.com/src/views/mobile/index/detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="helpCenter" style="font-size: 12px;">
    <div class="detail" v-html="content"></div>
  </div>
</template>

<script>
import {getQueDetail  } from "../../api/index";
export default {
  components: {
    
  },
  data() {
    return {
      id:null,
      content:''
    };
  },
  created() {
    this.id=this.$route.query.id
  },
  mounted() {
    this.getQueDetail()
  },
  methods: {
    getQueDetail(){
      let param={
        id:this.id
      }
      getQueDetail(param).then(res=>{
          console.log('res',res)
          this.content=res
        // if(res.code==200){
        //   document.title = res.result.title
        //   this.content=res.result.content
        // }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

::v-deep .search-result {
}
.detail{
  width: 100%;
  padding-top: 50px;
  padding:20px;
  box-sizing:border-box;
}
::v-deep p{
  font-size: 30px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}
 ::v-deep img{
    width: 100% !important;
    height: 100% !important;
    background-size: cover;
    margin:20px 0;
  }
::v-deep div{
  font-size: 30px;
  margin-bottom: 20px;
}   
::v-deep h1{
  font-size: 40px;
  margin-bottom: 20px;
}  
::v-deep h2{
  font-size: 40px;
  margin-bottom: 20px;
}
::v-deep h3{
  font-size: 40px;
  margin-bottom: 20px;
}
::v-deep h4{
  font-size: 30px;
  margin-bottom: 20px;
}
::v-deep h5{
  font-size: 20px;
  margin-bottom: 20px;
}
::v-deep td, ::v-deep th{
  font-size: 30px;
  margin-bottom: 20px;
}

</style>
