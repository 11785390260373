import { render, staticRenderFns } from "./mdetail.vue?vue&type=template&id=5452c2ab&scoped=true&"
import script from "./mdetail.vue?vue&type=script&lang=js&"
export * from "./mdetail.vue?vue&type=script&lang=js&"
import style0 from "./mdetail.vue?vue&type=style&index=0&id=5452c2ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5452c2ab",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/qa.jaguarjob.com/qa.jaguarjob.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5452c2ab')) {
      api.createRecord('5452c2ab', component.options)
    } else {
      api.reload('5452c2ab', component.options)
    }
    module.hot.accept("./mdetail.vue?vue&type=template&id=5452c2ab&scoped=true&", function () {
      api.rerender('5452c2ab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mobile/mdetail.vue"
export default component.exports